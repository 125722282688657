import React, { createContext, useContex, t, useEffect, useState } from "react";
import { auth } from "../firebase-config";
import { onAuthStateChanged, signInWithEmailAndPassword, signOut, setPersistence, browserLocalPersistence } from 'firebase/auth';

const AuthContext = createContext({
  currentUser: null,
  login: () => { },
  logout: () => { },
});

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // Set persistence to LOCAL (or SESSION if you prefer)
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          setCurrentUser(user);
        });
        return unsubscribe;
      })
      .catch((error) => {
        // Handle errors related to persistence setup
        console.error("Error setting persistence:", error);
      });
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      // Handle errors here
      console.error(error.message);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};