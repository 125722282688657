import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { Container, Button } from 'react-bootstrap';

const Dashboard = () => {
  const { currentUser, logout } = useAuth();

  return (
    <>
      <Container fluid className='mt-3' >
        <div style={{ margin: 'auto', width: '800px', maxWidth: '100%' }}>
          <h3>Welcome, {currentUser.email}!</h3>
          <Button variant="primary" onClick={logout} className='mt-3'>
          </Button>
        </div>
      </Container>
    </>

  );
};

export default Dashboard;