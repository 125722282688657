
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'; 

const firebaseConfig = {
  apiKey: "AIzaSyBc8-ZF6oXinvlTkqcOD5kCU7LT3J2NIUE",
  authDomain: "oc-collection-76eed.firebaseapp.com",
  databaseURL: "https://oc-collection-76eed-default-rtdb.firebaseio.com",
  projectId: "oc-collection-76eed",
  storageBucket: "oc-collection-76eed.appspot.com",
  messagingSenderId: "387269450800",
  appId: "1:387269450800:web:4e65ce02f7d1d88417e1f5",
  measurementId: "G-FF8NPX94F1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);