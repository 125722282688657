import React, { useState, useContext } from 'react';
import { useNavigate} from 'react-router-dom';
import Header from '../components/Header';
import Container from 'react-bootstrap/Container';
import { Form, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';

const Login = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            setError('Please enter both email and password.');
            return;
        }
        try {
            login(email, password);
            navigate('/dashboard'); // Redirect on successful login
        } catch (error) {
           
            setError(error.message);
        }
    };

    return (
        <>
            <Header />
            <Container fluid className='mt-3' >
                <div style={{ margin: 'auto', width: '400px', maxWidth: '100%' }}>
                    <Form onSubmit={handleSubmit}>
                        {error && <Alert variant="danger">{error}</Alert>} {/* Display error message */}
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="submit" className='mt-3'>
                            Login
                        </Button>
                    </Form>

                </div>
            </Container>


        </>

    );
}

export default Login;