import React from 'react';
import { Helmet } from 'react-helmet';
import Home from '../pages/Home';
import Blog from '../pages/Blog';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Login from '../pages/Login';
import Dashboard from '../pages/admin/Dashboard';
import NotFound from '../pages/404';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from '../context/AuthContext';
import ProtectedRoute from '../components/ProtectedRoute';

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}

/*
const router = createBrowserRouter([
    {
        path: '/',
        element:
            <>
                <Helmet>
                    <title>Home</title>
                </Helmet>
                <Home />
            </>
        ,
    },
    {
        path: '/blog',
        element:
            <>
                <Helmet>
                    <title>Blog</title>
                </Helmet>
                <Blog />
            </>,
    },
    ,
    {
        path: '/about',
        element:
            <>
                <Helmet>
                    <title>About</title>
                </Helmet>
                <About />
            </>,
    },
    ,
    {
        path: '/contact',
        element:
            <>
                <Helmet>
                    <title>Contact</title>
                </Helmet>
                <Contact />
            </>
        ,
    },
    {
        path: '/login',
        element:
            <>
                <Helmet>
                    <title>Login</title>
                </Helmet>
                <Login />
            </>
        ,
    },
    {
        path: '/dashboard',
        element:
            <>
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>
                <Dashboard />
            </>
        ,
    },
    {
        path: '*',
        element:
            <>
                <Helmet>
                    <title>Page not found</title>
                </Helmet>
                <NotFound />
            </>
        ,
    },

]); */

export default App;