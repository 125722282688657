import Header from '../components/Header';
import Container from 'react-bootstrap/Container';
export default function Home() {
    return (
        <>
            <Header />
            <Container fluid className='mt-3' >
                <div style={{ margin: 'auto', width: '800px', maxWidth: '100%' }}>
                  
                    <h2>Welcome to my GachaSenpai site</h2>
                        <div className='mb-2'>
                        Hello, my name is Kiya Akansa. I'm a self-employed. I earn income from Adsense.
                        So far I have earned income from Adsense, via YouTube (no longer), and now from my Android App that I created and monetized it.
                        </div>
                        <div className='mb-2'>
                        I thank the gacha community for supporting me all this time, especially the active users of my application.
                        </div>
                        <div className='mb-2'>
                        You can download my latest App on the Google Play <a href="https://play.google.com/store/apps/details?id=com.gachasenpai.occollection" target='_blank'>here</a>
                        </div>
                   
                  
                </div>

            </Container>


        </>
    );
}